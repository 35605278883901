<template>
  <main id="content" role="main">
    <section class="visual_intro">
      <!-- 다른곳과 동일하게. 넣어야 할 이미지는 letter/example.png 로 넣어 두었습니다.-->
      <div class="visual_wall">
      </div>
      <div class="intro_title">
        <strong class="title">DREAMPLUS</strong>
        <strong class="title">NEWSLETTER</strong>
        <router-link :to="{name:'LetterRead', params:{}}"  class="link-letter-read">구독하기</router-link>
      </div>
    </section>
    <section class="report_box">
      <div class="news-tab-menu">
        <ul>
          <li><router-link to="/contents/list">NEWS</router-link></li>
          <li class="on"><router-link to="/letter/list">NEWSLETTER</router-link></li>
        </ul>
      </div>
    </section>
    <section class="report_group">
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">NEWSLETTER</h2>
          <p class="description">DREAMPLUS NEWSLETTER를 통해 드림플러스의 다양한 활동 및 생태계 소식을 전해 드립니다.</p>
        </div>
        <div class="letter-list">
          <ul>
            <li v-for="item in dataList">
              <div class="thumb">
                <span :style="`background-image: url(${item.thumbnailUrl});`" @click.prevent="showDetail(item.id)"></span>
              </div>
              <div class="info">
                <span class="title" @click.prevent="showDetail(item.id)">{{ item.title }}</span>
                <span class="content" @click.prevent="showDetail(item.id)">{{ item.description }}</span>
                <a class="link" @click.prevent="showDetail(item.id)">상세내용 보러가기</a>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <div class="btn-wrap">
        <button button class="btn-more" v-show="moreVisible" @click="retrieveNewsListMore">
          <span class="circle">
            <span class="icon-plus">+</span>
          </span>
          <em class="btn-text">{{$t('content.news.list.more')}}</em>
        </button>
      </div>
      <!-- <MoreButton v-if="moreVisible" @click="retrieveNewsListMore"></MoreButton> -->
    </section>
  </main>
</template>

<script>


import MoreButton from '@/components/base/MoreButton.vue';
import LetterList from './LetterList.vue';

export default {
  name: 'MobileLetterList',
  extends: LetterList,
  components: {
    MoreButton
  },
  created () {
    this.isMobile = true;
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  mounted() {
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  }
};
</script>
