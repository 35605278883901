<template>
    <div class="pagination" v-if="size>0">
      <a class="navi first" v-if="startPage>1" @click="getList(1)"></a>
      <a class="navi prev" v-if="pageNo>1" @click="getList(pageNo-1)"></a>
      <div class="paging">
        <template  v-if="startPage<endPage">
          <template v-for="n in endPage" v-if="n >= startPage">
            <template v-if="n == pageNo">
              <a class="page active">{{n}}</a>
            </template>
            <template v-else>
              <a class="page" @click="getList(n)">{{n}}</a>
            </template>
          </template>
        </template >
        <template v-else>
          {{ startPage }}
        </template>
      </div>
      <a class="navi next" v-if="pageNo<totalPage" @click="getList(pageNo+1)"></a>
      <a class="navi last" v-if="endPage<totalPage" @click="getList(totalPage)"></a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Pagination',
    created() {
      this.$emit('handler', this.loadPage);
    },
    components: {
    },
    watch: {
    },
    computed: {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    data() {
      return {
        totalPage:1,
        pageNo:1,
        startPage:1,
        endPage:1,
        size:1
      }
    },
    methods: {
      getList(n){
          this.$parent.getList(n);
      },
      loadPage: function(totalCount, size, page) {
        this.size = size;
        this.pageNo = page;
        this.totalPage = Math.ceil(totalCount / size);
        if (this.totalPage < page) { this.totalPage = page; }
        this.startPage = Math.floor((page-1)/10)*10+1;
        this.endPage = this.startPage + 9;
        if (this.endPage > this.totalPage) { this.endPage = this.totalPage; }
      }
    }
  };
  </script>

<style scoped>
/* pagination */
.pagination {
  text-align: center;
  vertical-align: middle;
  margin-top: 100px;
}
.pagination * {
  vertical-align: middle;
}
.pagination a {
  width: 32px;
  height: 32px;
  display: inline-block;
  line-height: 32px;
  font-size: 12px;
  font-weight: normal;
  color: #cecece;
  cursor: pointer;
}
.pagination a.active {
  font-size: 15px;
  color: #000;
}
.pagination div.paging {
  display: inline-block;
}
.pagination .navi {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  transition: all 0.5s;
}
.pagination .navi.first,
.pagination .navi.last {
  background-image: url('../../assets/images/pagination/navi_first.png');
}
.pagination .navi.next {
  transform: rotate(180deg);
}
.pagination .navi.prev,
.pagination .navi.next {
  background-image: url('../../assets/images/pagination/navi_prev.png');
}
.pagination .navi.next {
  transform: rotate(180deg);
}
.pagination .navi.last {
  background-image: url('../../assets/images/pagination/navi_first.png');
  transform: rotate(180deg);
}
.pagination .navi:hover.first,
.pagination .navi:hover.last {
  background-image: url('../../assets/images/pagination/navi_first_on.png');
}
.pagination .navi:hover.prev,
.pagination .navi:hover.next {
  background-image: url('../../assets/images/pagination/navi_prev_on.png');
}
</style>
  