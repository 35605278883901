<template>
  <button
    class="btn-more"
    @click="onClick"
  >
    <span class="circle">
        <span class="icon-plus"> + </span>
    </span>
    <span class="btn-text">{{ $t('content.common.button.btn20') }}</span>
  </button>
</template>

<script>
/**
 * 더보기 Button 컴포넌트
 */
export default {
  name: 'MoreButton',
  methods: {
    onClick(event) {
      /**
       * 클릭 이벤트
       * @event click
       * @property {Event} 이벤트 https://developer.mozilla.org/ko/docs/Web/API/Element/click_event
       */
      this.$emit('click', event);
    },
  },
};
</script>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<MoreButton
  @click="showAlert('좀더 보여드립니다.')"
/>
```
</docs>
